import set from 'lodash/set'

export const validate = (schema) => async (values) => {
  try {
    await schema.validate(values, { abortEarly: false })
    return {}
  } catch (err) {
    const error = (err.inner || []).reduce((acc, curr) => {
      return set(acc, curr.path, curr.message)
    }, {})
    return error
  }
}
