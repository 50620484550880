import { FC } from 'react'
import styled from '@emotion/styled'

import {
  SaveButton,
  DeleteButton,
  Toolbar as RaToolbar,
  ToolbarProps
} from 'react-admin'
import React from 'react'

const FlexToolbar = styled(RaToolbar)`
  display: flex;
  justify-content: space-between;
`

export const CustomToolbar: FC<ToolbarProps> = (props) => {
  // note: if upgrading to @types/react@18.2.43 or newer, FlexToolbar will start throwing errors
  return (
    <FlexToolbar {...props}>
      <SaveButton />
      <DeleteButton mutationMode="optimistic" />
    </FlexToolbar>
  )
}
